dmx.signalProxy = function (o = {}) {
  const signals = Object.create(null);
  const equals = (a, b) => {
    if (a !== b) return false;
    if (a instanceof dmx.BaseComponent) return false;
    return dmx.equal(a, b);
  };

  return new Proxy(o, {
    get (target, prop, receiver) {
      const value = Reflect.get(target, prop, receiver);

      if (typeof prop == 'string' && prop.startsWith('_')) {
        // no signals on private props
        return value;
      }

      if (!signals[prop]) {
        signals[prop] = dmx.signal(value, { equals });
      }

      return signals[prop].value;
    },

    set (target, prop, value, receiver) {
      const ok = Reflect.set(target, prop, value, receiver);

      if (typeof prop == 'string' && prop.startsWith('_')) {
        // no signals on private props
        return ok;
      }

      if (ok) {
        if (!signals[prop]) {
          signals[prop] = dmx.signal(value, { equals });
        } else {
          signals[prop].value = value;
        }
      }

      return ok;
    },

    deleteProperty (target, prop) {
      const ok = Reflect.deleteProperty(target, prop);

      if (ok && signals[prop]) {
        signals[prop].value = undefined;
      }

      return ok;
    }
  });
};