dmx.Attribute("on", "mounted", function (node, attr) {
  const component = this;

  dmx.eventListener(node, attr.argument, function (event) {
    // jQuery event
    if (event.originalEvent) event = event.originalEvent;

    const retValue = dmx.parse(attr.value, dmx.DataScope({
      $event: event.$data,
      $originalEvent: event,
    }, component));

    return retValue;
  }, attr.modifiers);
});
