dmx.Component('checkbox-group', {

  initialData: {
    value: [],
  },

  attributes: {
    value: {
      type: Array,
      default: [],
    },
  },

  methods: {
    setValue (value) {
      this._setValue(value);
    },
  },

  events: {
    updated: Event,
  },

  init (node) {
    this._changeHandler = this._changeHandler.bind(this);

    node.addEventListener('change', this._changeHandler);

    this._setValue(this.props.value, true);
  },

  destroy () {
    this.$node.removeEventListener('change', this._changeHandler);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('value')) {
      this._setValue(this.props.value, true);
    }
  },

  _setValue (value, defaultValue) {
    if (value == null) value = '';
    if (!Array.isArray(value)) value = [value];
    value = value.map(v => v.toString());

    this._checkboxes().forEach(checkbox => {
      checkbox.checkbox = value.includes(checkbox.value);
      if (defaultValue) checkbox.defaultChecked = checkbox.checked;
    });

    this._updateValue();
  },

  _updateValue () {
    const value = this._checkboxes().filter(checkbox => !checkbox.disabled && checkbox.checked).map(checkbox => checkbox.value);

    if (!dmx.equal(this.data.value, value)) {
      this.set('value', value);
      this.dispatchEvent('updated');
    }
  },

  _checkboxes () {
    return Array.from(this.$node.querySelectorAll('input[type=checkbox]'));
  },

  _changeHandler (event) {
    this._updateValue();
  },

});
