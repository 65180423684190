(() => {

  class Scope {

    constructor (initialData = {}, parent = null) {
      if (typeof initialData !== 'object') {
        initialData = { $value: initialData };
      }

      this.data = dmx.signalProxy();
      Object.assign(this.data, initialData);
      this.parent = parent;
      this.seed = Math.random();
    }

    get (name) {
      const value = this.data[name];

      if (this.data.hasOwnProperty(name)) {
        return this.data[name];
      }

      if (this.parent) {
        if (name == 'parent') {
          return this.parent.data;
        }

        return this.parent.get(name);
      }

      return null;
    }

    set (name, value) {
      if (typeof name === 'object') {
        for (const prop in name) {
          if (name.hasOwnProperty(prop)) {
            this.set(prop, name[prop]);
          }
        }
      } else {
        this.data[name] = value;
      }
    }

    has (name) {
      return this.data.hasOwnProperty(name);
    }

    del (name) {
      delete this.data[name];
    }

  }

  dmx.global = new Scope();
  dmx.DataScope = function (data, parent) {
    return new Scope(data, parent || dmx.global);
  };

})();