dmx.Component('input-file-multiple', {

  extends: 'form-element',

  initialData: {
    files: [],
  },

  _setValue (value) {
    console.warn('Can not set value of a file input!');
  },

  _changeHandler (event) {
    dmx.Component('form-element').prototype._changeHandler.call(this, event);

    let files = [];
    
    if (this.$node.files.length) {
      const self = this;

      files = Array.from(this.$node.files).map((file, index) => {
        let dataUrl = null;
        let loading = false;
        
        const data = {
          date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
          name: file.name,
          size: file.size,
          type: file.type,
          get dataUrl () {
            if (!dataUrl && !loading) {
              loading = true;
              dmx.fileUtils.blobToDataURL(file).then(dataUrl => {
                files = dmx.clone(files);
                files[index].dataUrl = dataUrl;
                self.set('files', files);
              }).catch(error => {
                console.error(error);
              });
            }

            return dataUrl;
          },
        };

        return data;
      });
    }

    this.set('files', files);
  },

});
