dmx.Attribute("hide", "mounted", function (node, attr) {
  const initial = {
    value: node.style.getPropertyValue('display'),
    priority: node.style.getPropertyPriority('display'),
  };

  this.$watch(attr.value, hide => {
    const value = hide ? 'none' : initial.value;
    const priority = hide ? 'important' : initial.priority;

    node.style.setProperty('display', value, priority);
  });
});
