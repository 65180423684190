dmx.Component('value', {

  initialData: {
    value: null,
  },

  attributes: {
    value: {
      default: null,
    },
  },

  methods: {
    setValue (value) {
      if (this.data.value !== value) {
        this.set('value', value);
        this.dispatchEvent('updated');
      }
    },
  },

  events: {
    updated: Event,
  },

  render: false,

  init (node) {
    this.set('value', this.props.value);
  },

  performUpdate (updatedProps) {
    if (updatedProps.has('value')) {
      this.set('value', this.props.value);
      this.dispatchEvent('updated');
    }
  },

});