dmx.Component('input-file', {

  extends: 'form-element',

  initialData: {
    file: null,
  },

  _setValue (value) {
    console.warn('Can not set value of a file input!');
  },

  _changeHandler (event) {
    dmx.Component('form-element').prototype._changeHandler.call(this, event);

    let data = null;
    
    if (this.$node.files.length) {
      const self = this;
      const file = this.$node.files[0];
      let dataUrl = null;
      let loading = false;

      data = {
        date: (file.lastModified ? new Date(file.lastModified) : file.lastModifiedDate).toISOString(),
        name: file.name,
        size: file.size,
        type: file.type,
        get dataUrl () {
          if (!dataUrl && !loading) {
            loading = true;
            dmx.fileUtils.blobToDataURL(file).then(dataUrl => {
              self.set('file', Object.assign({}, data, { dataUrl }));
            }).catch(error => {
              console.error(error);
            });
          }

          return dataUrl;
        },
      };
    }

    this.set('file', data);
  },

});
